/**
 * Inn Finder
 */

import axios from "axios";
import $ from "jquery";
import { errorAlert, successAlert } from "./alerts";
import { appendSuccess, appendError, removeMessage } from "./form-messages";

export default function(config, callback) {
  this.validate = config.validate;
  this.callback = callback;
  this.container = $(config.container || "body,html");

  this.fields = {
    surname: $("#" + config.fields.surname || "[data-inn=surname]"),
    name: $("#" + config.fields.name || "[data-inn=name]"),
    patronymic: $("#" + config.fields.patronymic || "[data-inn=patronymic]"),
    birthdate: $("#" + config.fields.birthdate || "[data-inn=birthdate]"),
    birthplace: $("#" + config.fields.birthplace || "[data-inn=birthplace]"),
    pass_serial: $("#" + config.fields.pass_serial || "[data-inn=pass_serial]"),
    pass_number: $("#" + config.fields.pass_number || "[data-inn=pass_number]"),
    pass_date: $("#" + config.fields.pass_date || "[data-inn=pass_date]")
  };

  this.btn = $(config.btn);

  this.data = {
    c: "innMy",
    fam: this.fields.surname.val(),
    nam: this.fields.name.val(),
    otch: this.fields.patronymic.val(),
    bdate: this.fields.birthdate.val(),
    bplace: this.fields.birthplace.val(),
    docno: this.fields.pass_serial.val() + " " + this.fields.pass_number.val(),
    docdt: this.fields.pass_date.val(),
    captcha: "",
    captchaToken: ""
  };

  /*
   * Updates state's data
   */
  this.update = function() {
    this.set("fam", this.fields.surname.val());
    this.set("nam", this.fields.name.val());
    this.set("otch", this.fields.patronymic.val());
    this.set("bdate", this.fields.birthdate.val());
    this.set("bplace", this.fields.birthplace.val());
    this.set(
      "docno",
      this.fields.pass_serial.val() +
        " " +
        this.fields.pass_number.val().replace(/\s/, "")
    );
    this.set("docdt", this.fields.pass_date.val());
  };

  this.captchaImg = "";

  this.set = function(attr, val) {
    attr == "captchaImg" ? (this.captchaImg = val) : (this.data[attr] = val);
  };

  this.remote = "https://doc.rostatus.ru/inn/get-captcha";
  this.remoteInn = "https://doc.rostatus.ru/inn/get-inn";

  this.handleCaptchaResponse = function(res) {
    this.set("captchaToken", res.data.token);
    this.set("captchaImg", res.data.img);
    this.appendCaptchaModal();
    this.showCaptchaModal();
    this.btn.prop("disabled", false).removeClass("is-loading");
  };

  this.handleInnResponse = function(res) {
    switch (res.data.code) {
      case 0:
        errorAlert(
          "Соответствующий введенной информации ИНН в базе ИФНС не найден",
          "ИНН не найден",
          -1
        );
        $("#captchaModal").modal("hide");
        break;

      case 1:
        setTimeout(() => {
          successAlert(
            `Ваш ИНН: ${res.data.inn}. Данные автоматически вставлены в форму.`,
            "Успех",
            -1
          );
          this.callback(res.data.inn);
          $("#captchaModal").remove();
        }, 2000);

        break;
    }
  };

  this.handleInnResponseError = function(res) {
    try {
      const err = res.response.data;
      const messages = [];

      for (let k in err.ERRORS) {
        messages.push(err.ERRORS[k]);
      }

      errorAlert(
        messages.join("<br>"),
        "ИНН узнать не удалось из-за следующих ошибок:",
        -1
      );

      $("#captchaModal").remove();
    } catch (err) {
      $("#captchaModal").remove();
    }
  };

  this.getInn = function() {
    axios
      .post(this.remoteInn, this.data)
      .then(this.handleInnResponse.bind(this))
      .catch(this.handleInnResponseError.bind(this));
  };

  this.getForm = function() {
    this.update();

    axios
      .get(this.remote)
      .then(this.handleCaptchaResponse.bind(this))
      .catch(err => {
        /* eslint-disable-next-line */
        console.error(err);
      });
  };

  this.handleSubmit = function() {
    if (this.setCaptcha($("#captcha").val())) {
      $("#getInn").addClass("is-loading");
      $("#getInn").prop("disabled", true);

      this.getInn();
    }
  };

  this.setCaptcha = function(value) {
    if (value.match(/^\d{6}$/)) {
      this.set("captcha", value);
      removeMessage($("#captcha"));
      appendSuccess($("#captcha"), "");

      return true;
    } else {
      removeMessage($("#captcha"));
      appendError($("#captcha"), "Капча должна состоять из 6 цифр.");

      return false;
    }
  };

  this.appendCaptchaModal = function() {
    $("#captchaModal").remove();

    const modal = `
      <div id="captchaModal" class="modal is-captcha" role="dialog">
        <div class="modal__overlay">
          <div class="modal__box">
            <div class="modal__content">
              <div class="captcha">
                <div class="captcha__header">
                  <h2 class="captcha__title">Узнать ИНН</h2>
                </div>
                <div class="captcha__body">
                  <div class="captcha__field">
                    <label class="captcha__label" for="captcha">Введите цифры с картинки</label>
                    <input class="captcha__input input" type="tel" name="captcha" placeholder="______" id="captcha" />
                    <img class="captcha__image" id="captchaImg" src='${this.captchaImg}' alt="Включите загрузку картинок, чтобы Вы смогли увидить изображение" />
                  </div>
                </div>
                <div class="captcha__footer">
                  <button type="button" class="captcha__button button is-bordered" data-dismiss="modal">Отмена</button>
                  <button type="button" class="captcha__button button" id="getInn">Узнать ИНН</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button class="modal__close" data-dismiss="modal"></button>
      </div>
    `;

    $("body").append(modal);
    $("#getInn").on("click", this.handleSubmit.bind(this));
    $('[data-dismiss="modal"]').click(function() {
      $("#captchaModal").remove();
    });
  };

  this.showCaptchaModal = function() {
    $("#captchaModal").addClass("is-open");
  };

  $(document).ready(() => {
    // Inn Btn Event
    this.btn.on("click", e => {
      e.preventDefault();

      try {
        this.validate();

        $(e.target)
          .prop("disabled", true)
          .addClass("is-loading");

        this.getForm();
      } catch (err) {
        setTimeout(() => {
          const $root = $("body,html");
          const $errors = $(".errors");

          if ($errors.length) {
            const $field = $errors.closest(".field");
            const scrollTop = $field.offset().top - 15;

            $root.animate({ scrollTop }, 200);

            errorAlert(
              "Исправьте ошибки заполнения формы, чтобы узнать Ваш ИНН",
              "Найдены ошибки в форме"
            );
          }
        }, 200);
      }
    });
  });

  return this;
}
