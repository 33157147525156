<template lang="pug">
  .form
    .form__header {{ header }}
    .form__body
      .form__description(
        v-if="description"
      ) {{ description }}

      .form__fields
        .grid.is-columns
          .form__field(
            v-for="(field, index) in visibleFields"
            :key="`${index}${field.id}`"
            :class="getFieldCol(field.width)"
          )
            Field(
              :field="field"
              :showError="getShowError(field)"
              @change='onChange'
            )
      .form__description(
        v-if="descriptionBottom"
        v-html="descriptionBottom"
      )
    .form__footer
      .form__button(
        v-if="activeStep > 0"
      )
        Button(
          :action="handlePrev"
          :classes="['is-large', 'is-bordered']"
          label="Назад"
        )
      .form__button
        Button(
          :action="validateBeforeNext"
          :classes="['is-large', 'has-arrow']"
          label="Продолжить"
        )
</template>

<script>
import $ from "jquery";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import { errorAlert } from "@/vendor/alerts";
import Field from "@/components/Field.vue";
import Button from "@/components/Button.vue";
import config from "@/config";

export default {
  props: {
    index: {
      type: Number,
      required: true
    },
    header: {
      type: String
    },
    description: {
      type: [String, Boolean],
      default: false
    },
    descriptionBottom: {
      type: [String, Boolean],
      default: false
    },
    fields: {
      type: Array,
      required: true
    },
    valid: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Field,
    Button
  },
  computed: {
    ...mapState("form", ["activeStep", "steps"]),
    ...mapGetters("form", ["lastStepIndex"]),
    active() {
      return this.index === this.activeStep;
    },
    isLastStage() {
      return this.index === this.lastStepIndex;
    },
    visibleFields(){
      return this.fields.filter((item)=>{
        return !item.hasOwnProperty("visible") || item.hasOwnProperty("visible") && item.visible;
      });
    },
  },
  methods: {
    ...mapActions({
      setCompleted: "stages/setCompleted"
    }),
    ...mapMutations("form", [
      "nextStep",
      "prevStep",
      "validateStep",
      "openModal",
      "closeModal",
      "setModalContent",
      "setModalTitle",
      "setModalConfirm",
      "setModalCancel",
      "updateFieldValidation"
    ]),
    getFieldCol(width) {
      switch (width) {
        case "one-half":
          return "col-6";
        case "one-third":
          return "col-4";
        case "two-third":
          return "col-8";
        case "one-fourth":
          return "col-3";
        default:
          return "col-12";
      }
    },
    getShowError(field){
      if(field.hasOwnProperty("showError")){
        return Boolean(field.showError);
      }
      else{
        return false;
      }
    },
    validateBeforeNext() {
      if (config.debug) {
        this.handleNext();
      } else {
        this.validateStep({
          success: this.handleNext.bind(this),
          error: this.handleErrors.bind(this)
        });
      }
    },
    handleErrors() {
      setTimeout(() => {
        const $root = $("body,html");
        const $errors = $(".errors");

        if ($errors.length) {
          const $field = $errors.closest(".field");
          const scrollTop = $field.offset().top - 15;

          $root.animate({ scrollTop }, 200);

          errorAlert(
            "Прежде чем перейти к следующему шагу, исправьте ошибки в соответствующих полях"
          );
        }
      }, 200);
    },
    handleNext() {
      if (this.isLastStage) {
        this.setCompleted("form");
        this.$router.push("varification");
      } else {
        this.nextStep();
      }
    },
    handlePrev() {
      this.prevStep();
    },
    validate(errors) {
      if (errors.length > 0) {
        this.valid = false;
      } else {
        this.valid = true;
      }
    },
    onChange({ fieldId, value }) {
      this.checkDeps({ fieldId, value });
    },
    checkDeps({ fieldId, value }) {
      const field = this.fields.find(field => field.id === fieldId);

      if (field && Array.isArray(field.deps)) {
        field.deps.forEach(id => {
          this.updateFieldValidation({
            id,
            validate: {
              required: value
                ? {
                    error: "Данное поле обязательно для заполнения"
                  }
                : null
            }
          });
        });
      }
    }
  }
};
</script>
<style lang="scss">
.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 515px;

  &__header {
    margin: 0;
    color: $accent;
    font-weight: $semibold;
    font-size: 26px;
    line-height: 1.23;
  }

  &__body {
    flex-grow: 1;
  }

  &__description {
    margin-top: 30px;
    color: $black;
    font-size: 18px;
    line-height: 1.23;

    a {
      color: $second;
      transition: ease color 200ms;

      &:hover {
        color: $accent;
      }
    }
  }

  &__fields {
    margin-top: 30px;
  }

  &__field {
    margin-bottom: 10px;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    flex-shrink: 0;
    margin-top: 50px;
  }

  &__button {
    & + & {
      display: flex;
      justify-content: flex-end;
      flex-grow: 1;
    }
  }
}
</style>
