<template lang="pug">
  .inn-button
    #inn-btn.inn-button__button.button.is-large
      span.inn-button__text {{ text }}
    .inn-button__legend(
      v-if="legend"
    ) {{ legend }}
</template>

<script>
import InnFinder from "@/vendor/inn.js";
import { mapState, mapMutations } from "vuex";

export default {
  props: ["fields", "field", "width", "text", "legend"],
  mounted() {
    let config = {
      fields: this.fields,
      btn: "#inn-btn",
      validate: this.validateInnForm.bind(this)
    };

    new InnFinder(config, this.handleSuccess.bind(this));
  },
  computed: {
    ...mapState("form", ["form"])
  },
  methods: {
    ...mapMutations("form", ["setValue", "validateFields"]),
    handleSuccess(value) {
      this.setValue({
        id: this.field,
        value
      });
    },
    validateInnForm() {
      this.validateFields({
        fields: Object.values(this.fields)
      });
    }
  }
};
</script>
<style lang="scss">
.button {
  &.is-loading {
    background-image: url(../img/spinner.svg);
    background-color: white;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    color: transparent;
    border: 1px solid #3b7bbe;
  }
}

.inn-button {
  margin-top: 40px;
  &__legend {
    margin-top: 15px;
    color: $grey;
    font-size: 16px;
  }
}
</style>
