export function ruPhoneNumberFormatter(phone) {

    phone = getCompactPhone(phone);

    let prefix = "";

    if (phone[0] === "7") {
        prefix = "+";
    }

    if (phone.length === 1) {
        if (["7", "8"].indexOf(phone) < 0) {
            phone = "+7 (" + phone;
        } else {
            phone = prefix + phone;
        }
    }

    if (phone.length > 9) {
        phone = phone.replace(/^(\d)(\d{3})(\d{3})(\d{2})(\d{0,2})$/, `${prefix}$1 ($2) $3-$4-$5`);
    } else if (phone.length > 7) {
        phone = phone.replace(/^(\d)(\d{3})(\d{3})(\d{0,2})$/, `${prefix}$1 ($2) $3-$4`);
    } else if (phone.length > 4) {
        phone = phone.replace(/^(\d)(\d{3})(\d{0,3})$/, `${prefix}$1 ($2) $3`);
    } else if (phone.length > 1) {
        phone = phone.replace(/^(\d)(\d{0,3})$/, `${prefix}$1 ($2`);
    }

    return phone;
}

export function getCompactPhone(phone) {
    return phone.replace(/\D+/gm, "");
}