function defaultMaskTypeFormatter({
    type,
    config,
}) {
    return {
        type,
        config,
    };
}

export function cleaveMaskTypeFormatter(config) {
    return defaultMaskTypeFormatter({
        type: 'cleave',
        config,
    });
}

export function nativeMaskTypeFormatter(config) {
    return defaultMaskTypeFormatter({
        type: 'native',
        config,
    });
}
