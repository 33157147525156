<template lang="pug">
  form.service-form(
    @submit.prevent="() => {}"
  )
    fieldset.service-form__step(
      v-for="(step, index) in visibleSteps"
      :key="index"
      :class='index === activeStep ? "is-active" : null'
    )
      Form(
        :header="step.header"
        :description="step.description"
        :descriptionBottom="step.descriptionBottom"
        :fields="step.fields"
        :index="step.index"
        :valid="step.valid ? true : false"
      )
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import Form from "@/components/Form.vue";

export default {
  computed: {
    ...mapState("form", ["steps", "activeStep", "plugins"]),
    ...mapGetters("form", ["visibleSteps"]),
  },
  methods: {
    ...mapMutations("form", ["setValue"]),
    loadPlugins() {
      if (this.plugins) {
        this.plugins.map(async ({ type, config }) => {
          const Plugin = await import(`@/plugins/${type}`);

          if (!Array.isArray(config)) {
            config = [config];
          }

          config.forEach(configItem => {
            const pluginInstance = new Plugin.default(
              configItem,
              this.setValue.bind(this)
            );

            pluginInstance.init();
          })
        });
      }
    }
  },
  components: {
    Form
  },
  mounted() {
    this.$nextTick(() => {
      this.loadPlugins();
    })
  }
};
</script>
<style lang="scss">
.service-form {
  color: $dark;

  &__step {
    display: none;
    margin: 0;
    padding: 0;
    border: none;

    &.is-active {
      display: block;
    }
  }
}
</style>
