<template>
  <div
    :class="
      'form-field__img-previewer' +
        (src == '' ? '' : ' form-field__img-previewer--with-image')
    "
  >
    <span v-if="canClose" @click="deleteImg" class="img-previewer__closer"
      >&times;</span
    >
    <img :src="preview" class="uploading-image" />
    <span class="img-previewer__info">
      <span class="img-previewer__title">Загрузить</span>
      <span class="img-previewer__description"
        >{{ extensions }}<br />{{ maxSize }} Мб</span
      >
    </span>
    <input :id="id" type="file" :accept="accept" @change="uploadImage" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      file: null,
      size: 0
    };
  },
  computed: {
    preview() {
      if (this.src.length > 0) {
        let ext = this.src.split(";")[0].split("/")[1];
        if (ext == "pdf") {
          return "https://online.rostatus.ru/img/file-pdf.jpg";
        } else {
          return this.src;
        }
      } else {
        return this.src;
      }
    },
    canClose() {
      return this.src != "";
    },
    maxSizeInBytes() {
      return this.maxSize * 1024 * 1024;
    },
    extensions() {
      let parts = this.accept.split(",");
      parts.map((part, k) => {
        parts[k] = part.split("/")[1];
      });
      return parts.join(", ");
    }
  },
  methods: {
    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = e => {
        // console.log(e);
        if (e.total < this.maxSizeInBytes) {
          this.file = e.target.result;

          this.$emit("file-uploaded", { src: e.target.result, id: this.id });
        } else {
          this.$emit("file-fail-size", e.total);
        }
      };
    },
    deleteImg() {
      this.$emit("file-deleted", this.id);
    }
  },
  props: {
    src: {
      type: String,
      default: ""
    },
    id: {
      type: String,
      required: true
    },
    maxSize: {
      type: Number,
      default: 3
    },
    accept: {
      type: String,
      default: "image/jpeg,image/png,image/jpeg"
    }
  }
};
</script>
