import $ from "jquery";

/**
 * @description Appends Message with className to specified input
 * @param {String/DOM Element/jQuery Selector} input Targeted input
 * @param {String} text text of the message
 * @param {Sting} className class of message
 */

export function appendMessage(input, text, className = false) {
  className ? (className = " " + className) : (className = "");

  var message = '<span class="message' + className + '">' + text + "</span>";

  if ($(input).is("select")) {
    $(input)
      .siblings(".chosen-container")
      .after(message);
  } else {
    $(input).after(message);
  }
}

/**
 * @description Appends Error Message to specified input
 * @param {String/DOM Element/jQuery Selector} input Targeted input
 * @param {String} text text of the message
 */
export function appendError(input, text) {
  $(input)
    .parents(".form-group")
    .addClass("has-error");
  $(input)
    .parents(".form-group")
    .removeClass("has-success");
  appendMessage(input, text, "message-error");
}

/**
 * @description Appends Success Message to specified input
 * @param {String/DOM Element/jQuery Selector} input Targeted input
 * @param {String} text text of the message
 */
export function appendSuccess(input, text) {
  $(input)
    .parents(".form-group")
    .addClass("has-success");
  $(input)
    .parents(".form-group")
    .removeClass("has-error");
  appendMessage(input, text, "message-success");
}

/**
 * @description Removes any message from provided input
 * @param {String/DOM Element/jQuery Selector} input Targeted input
 */
export function removeMessage(input) {
  $(input)
    .parents(".form-group")
    .removeClass("has-success");
  $(input)
    .parents(".form-group")
    .removeClass("has-error");
  $(input)
    .siblings(".message")
    .remove();
}
