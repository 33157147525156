import { render, staticRenderFns } from "./Errors.vue?vue&type=template&id=34e38b22&lang=pug&functional=true&"
var script = {}
import style0 from "./Errors.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports