import {
    BANK_ACCOUNT_NUMBER_MASK_TYPE,
    DATE_MASK_TYPE, DIVISION_CODE_MASK_TYPE, INN_MASK_TYPE,
    NAME_MASK_TYPE,
    NUMBER_MASK_TYPE, PASSPORT_NUMBER_MASK_TYPE, PASSPORT_SERIES_MASK_TYPE,
    POSITIVE_NUMBER_MASK_TYPE, REQUEST_INFORMATION_NUMBER_OF_COPIES_MASK_TYPE, SNILS_MASK_TYPE
} from "../maskTypes";

export default function (mask) {
    switch (mask) {
        case '99.99.9999':
        case 'dd.mm.yyyy':
        case 'date':
            return DATE_MASK_TYPE;
        case '9{1,}':
            return POSITIVE_NUMBER_MASK_TYPE;
        case 'f{1,}':
            return NAME_MASK_TYPE;
        case '99 99':
        case '9999':
            return PASSPORT_SERIES_MASK_TYPE;
        case '999 999':
        case '999999':
            return PASSPORT_NUMBER_MASK_TYPE;
        case '999999999999':
            return INN_MASK_TYPE;
        case '999-999-999 99':
            return SNILS_MASK_TYPE;
        case '999-999':
            return DIVISION_CODE_MASK_TYPE;
        case 'n':
            return REQUEST_INFORMATION_NUMBER_OF_COPIES_MASK_TYPE;
        case '9999 9999 9999 9999 9999':
            return BANK_ACCOUNT_NUMBER_MASK_TYPE;
        default:
            return mask;
    }
}