import {
    DATE_MASK_TYPE,
    DIVISION_CODE_MASK_TYPE,
    INN_MASK_TYPE,
    KBK_MASK_TYPE,
    KPP_MASK_TYPE,
    NAME_MASK_TYPE,
    NUMBER_MASK_TYPE,
    OGRN_MASK_TYPE,
    PASSPORT_NUMBER_MASK_TYPE,
    PASSPORT_SERIES_MASK_TYPE,
    PHONE_NUMBER_MASK_TYPE,
    PLACE_MASK_TYPE,
    POSITIVE_NUMBER_MASK_TYPE, ZIP_CODE_MASK_TYPE,
    REQUEST_INFORMATION_NUMBER_OF_COPIES_MASK_TYPE,
    SNILS_MASK_TYPE, BIK_CODE_MASK_TYPE, LEGAL_ENTITY_INN_MASK_TYPE, BANK_ACCOUNT_NUMBER_MASK_TYPE
} from "../maskTypes";
import {cleaveMaskTypeFormatter, nativeMaskTypeFormatter} from "../maskFormatters";
import moment from "moment/moment";

const defaultMinDate = moment(new Date('1900-01-01').getTime()).format('YYYY-MM-DD');
const defaultMaxDate = moment(new Date().getTime()).format('YYYY-MM-DD');

export default function ({
                             type,
                             setValue,
                             mask,
                             id,
                         }) {
    switch (type) {
        case DATE_MASK_TYPE:
            const dateMax = mask?.max && mask.max !== 'today' ? mask.max : defaultMaxDate;

            return cleaveMaskTypeFormatter({
                date: true,
                delimiter: '.',
                datePattern: ['d', 'm', 'Y'],
                dateMin: mask.min ?? defaultMinDate,
                dateMax,
                onValueChanged: (e) => {
                    const date = e.target.value;

                    if (date && /\d{2}\.\d{2}\.0000/.test(date)) {
                        const minDate = moment(defaultMinDate);
                        setValue({value: minDate.format('DD.MM.YYYY'), id});
                    }
                },
            });
        case NUMBER_MASK_TYPE:
            return cleaveMaskTypeFormatter({
                numeral: true,
                numeralDecimalMark: '',
                delimiter: '',
            });
        case POSITIVE_NUMBER_MASK_TYPE:
            return cleaveMaskTypeFormatter({
                numeral: true,
                numeralDecimalMark: '',
                delimiter: '',
                numeralPositiveOnly: true,
            });
        case NAME_MASK_TYPE:
            return nativeMaskTypeFormatter({
                regex: /^[A-Za-zА-Яа-яёЁ\-\s]+$/,
            });
        case PLACE_MASK_TYPE:
            return nativeMaskTypeFormatter({
                regex: /^[A-Za-zА-Яа-яёЁ\-\s,.0-9]+$/,
            });
        case PASSPORT_SERIES_MASK_TYPE:
            return cleaveMaskTypeFormatter({
                numericOnly: true,
                delimiter: ' ',
                blocks: [2, 2],
            });
        case PASSPORT_NUMBER_MASK_TYPE:
            return cleaveMaskTypeFormatter({
                numericOnly: true,
                delimiter: ' ',
                blocks: [3, 3],
            });
        case INN_MASK_TYPE:
            return cleaveMaskTypeFormatter({
                numericOnly: true,
                delimiter: '',
                blocks: [12],
            });
        case SNILS_MASK_TYPE:
            return cleaveMaskTypeFormatter({
                numericOnly: true,
                delimiters: ['-', '-', ' '],
                blocks: [3, 3, 3, 2],
            });
        case DIVISION_CODE_MASK_TYPE:
            return cleaveMaskTypeFormatter({
                numericOnly: true,
                delimiter: '-',
                blocks: [3, 3],
            });
        case REQUEST_INFORMATION_NUMBER_OF_COPIES_MASK_TYPE:
            return nativeMaskTypeFormatter({
                regex: /^[1-5]$/,
            });
        case OGRN_MASK_TYPE:
            return cleaveMaskTypeFormatter({
                numericOnly: true,
                delimiter: '',
                blocks: [13],
            });
        case KBK_MASK_TYPE:
            return cleaveMaskTypeFormatter({
                numericOnly: true,
                delimiter: '',
                blocks: [20],
            });
        case KPP_MASK_TYPE:
            return cleaveMaskTypeFormatter({
                numericOnly: true,
                delimiter: '',
                blocks: [9],
            });
        case ZIP_CODE_MASK_TYPE:
            return cleaveMaskTypeFormatter({
                numericOnly: true,
                delimiter: '',
                blocks: [6],
            });
        case BIK_CODE_MASK_TYPE:
            return cleaveMaskTypeFormatter({
                numericOnly: true,
                delimiter: '',
                blocks: [9],
            });
        case LEGAL_ENTITY_INN_MASK_TYPE:
            return cleaveMaskTypeFormatter({
                numericOnly: true,
                delimiter: '',
                blocks: [10],
            });
        case BANK_ACCOUNT_NUMBER_MASK_TYPE:
            return cleaveMaskTypeFormatter({
                numericOnly: true,
                delimiter: ' ',
                blocks: [4, 4, 4, 4, 4],
            });
        case PHONE_NUMBER_MASK_TYPE:
        default:
            return null;
    }
}