/**
 * Маска для полей дат
 * @type {string}
 */
export const DATE_MASK_TYPE = 'date';

/**
 * Маска для числовых полей (отрицательные и положительные числа)
 * @type {string}
 */
export const NUMBER_MASK_TYPE = 'number';

/**
 * Маска для числовых полей (только положительные числа)
 * @type {string}
 */
export const POSITIVE_NUMBER_MASK_TYPE = 'positive_number';

/**
 * Маска для полей имени (ФИО, название, наименование и т.п.)
 * @type {string}
 */
export const NAME_MASK_TYPE = 'name';

/**
 * Место рождения, гражданство и т.д.
 * @type {string}
 */
export const PLACE_MASK_TYPE = 'place';

/**
 * Серия паспорта
 * @type {string}
 */
export const PASSPORT_SERIES_MASK_TYPE = 'passport_series';

/**
 * Номер паспорта
 * @type {string}
 */
export const PASSPORT_NUMBER_MASK_TYPE = 'passport_number';

/**
 * ИНН
 * @type {string}
 */
export const INN_MASK_TYPE = 'inn';

/**
 * СНИЛС
 * @type {string}
 */
export const SNILS_MASK_TYPE = 'snils';

/**
 * Код подразделения
 * @type {string}
 */
export const DIVISION_CODE_MASK_TYPE = 'division_code';

/**
 * Количество экземпляров для запроса информации
 * @type {string}
 */
export const REQUEST_INFORMATION_NUMBER_OF_COPIES_MASK_TYPE = 'request_information_number_of_copies';

/**
 * ОГРН
 * @type {string}
 */
export const OGRN_MASK_TYPE = 'ogrn';


/**
 * КБК
 * @type {string}
 */
export const KBK_MASK_TYPE = 'kbk';

/**
 * КПП
 * @type {string}
 */
export const KPP_MASK_TYPE = 'kpp';

/**
 * Номер телефона
 * @type {string}
 */
export const PHONE_NUMBER_MASK_TYPE = 'phone_number';

/**
 * Почтовый индекс
 * @type {string}
 */
export const ZIP_CODE_MASK_TYPE = 'zip_code';

/**
 * БИК
 * @type {string}
 */
export const BIK_CODE_MASK_TYPE = 'bik';

/**
 * ИНН ЮЛ
 * @type {string}
 */
export const LEGAL_ENTITY_INN_MASK_TYPE = 'legal_entity_inn';

/**
 * Номер счета в банке
 * @type {string}
 */
export const BANK_ACCOUNT_NUMBER_MASK_TYPE = 'bank_account_number';
