<template lang="pug">
  .box(
    :class='classList'
  )
    slot
</template>

<script>
export default {
  props: ["classList"]
};
</script>

<style lang="scss">
.box {
  display: flex;
  width: 596px;
  min-height: 575px;
  padding: 30px;
  background-color: $white;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.15);
  border-radius: 14px;

  &.is-success {
    min-height: auto;
  }
}
</style>
