<template lang="pug">
  .checkbox(
    :class="fieldClass"
  )
    label.checkbox__label(
      v-if="label"
      v-html="labelText"
    ) 
    
    .checkbox__field(
      @click="toggleValue"
    )
      //- input.checkbox__input(
      //-   type="checkbox"
      //-   v-model="fieldValue"
      //-   :name="name"
      //-   @change="onChange"
      //- )

      label.checkbox__placeholder(
        :for="id"
      ) {{ computedPlaceholder }}

        .checkbox__tooltip(
          v-if="tooltip"
        )
          Tooltip(
            :tooltip='tooltip'
          )

    label.checkbox__errors(
      v-if="error && showError"
      :for='id'
    )
      Errors(
        :errors='[error.error]'
      )
</template>

<script>
import { mapMutations } from "vuex";
import bbobHTML from '@bbob/html'
import presetHTML5 from '@bbob/preset-html5'
import Validator from "@/vendor/validate-us.js";
import Tooltip from "@/components/Tooltip.vue";
import Errors from "@/components/Errors.vue";

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: [String, Boolean],
      default: false
    },
    placeholder: {
      type: [String, Boolean],
      default: false
    },
    icon: {
      type: [String, Boolean],
      default: false
    },
    value: {
      type: Boolean,
      default: false
    },
    validate: {
      type: [Object, Boolean],
      default: false
    },
    width: {
      type: String,
      default: "full"
    },
    short: {
      type: Boolean,
      default: false
    },
    mutateFields: {
      type: [Array, Boolean],
      default: false
    },
    showError: {
      type: Boolean,
      default: false,
    },
    bbcode: {
      type: Boolean,
      default: false
    },
  },
  components: {
    Tooltip,
    Errors
  },
  computed: {
    fieldClass() {
      return {
        "is-error": this.error,
        "is-short": this.short,
        "is-checked": this.value,
        "has-label": Boolean(this.label),
      };
    },
    computedPlaceholder() {
      let parts = this.placeholder.split("[*]");
      return parts[0];
    },
    tooltip() {
      let matches = this.placeholder.match(/(\[\*\])(.*)(\[\/\*\])/);

      return matches != null ? matches[2] : false;
    },
    labelText () {
      if (this.bbcode) {
        return bbobHTML(this.label, presetHTML5())
      }
      return this.label
    },
    error: function() {
      if (this.validate) {
        let error = Validator.validateField({
          value: this.value,
          validate: this.validate
        });

        return error !== true ? error : "";
      }

      return "";
    }
  },
  methods: {
    ...mapMutations("form", ["setValue", "toggleFields", "mutateField"]),
    toggleValue() {
      let nv;

      this.value === true ? (nv = false) : (nv = true);

      let data = {
        id: this.id,
        value: nv
      };

      this.setValue(data);
      this.$emit("change", { value: nv });

      if (this.mutateFields) {

        this.mutateFields.map((field) => {
          const needToMutate = field.value === this.value;

          // this.toggleFields({
          //   fields: this.mutateFields,
          //   value: needToMutate || field.value,
          // });

        })

        this.toggleFields({
          fields: this.mutateFields,
          value: nv
        });
      }
      
    }
  },
  mounted() {
    if (this.mutateFields) {
      let nv;
      this.value === true ? (nv = false) : (nv = true);
      this.toggleFields({
        fields: this.mutateFields,
        value: this.value,
      })
    }
  },
};
</script>

<style lang="scss">
.checkbox {
  $this: &;

  width: 100%;
  position: relative;
  color: $black;
  font-family: $font-family;
  font-weight: $medium;
  font-style: normal;
  font-size: 16px;
  text-align: left;
  cursor: pointer;

  &.is-checked {
    #{$this}__placeholder::after {
      opacity: 1;
    }
  }

  &__field {
  }

  &.has-label{
    display: flex;
    flex-direction: row-reverse;

    .checkbox__field{
      min-width: 64px;
      margin-top: 10px;
    }
  }

  &__input {
    margin: 0;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }

  &__label {
  }

  &__placeholder {
    display: block;
    padding-left: 38px;
    position: relative;
    color: currentColor;
    line-height: 1.4;
    cursor: pointer;

    &::before {
      content: "";
      display: block;
      width: 30px;
      height: 30px;
      position: absolute;
      top: -5px;
      left: 0;
      border-radius: 4px;
      background-color: $white;
      border: 1px solid rgba(59, 123, 190, 0.8);
    }

    &::after {
      content: "";
      display: block;
      width: 17px;
      height: 14px;
      position: absolute;
      top: 2px;
      left: 7px;
      background-size: contain;
      background-image: url(../img/check.svg);
      background-repeat: no-repeat;
      background-position: 50% 50%;
      opacity: 0;
      transition: opacity ease 300ms;
    }
  }

  &__tooltip {
    display: inline-block;
    margin-left: 4px;
  }

  &__errors {
    display: block;
    position: absolute;
    top: calc(100% - 5px);
    left: 15px;
    z-index: $layer-1;
  }
}
</style>
