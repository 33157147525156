<template>
  <div class="extendable-fields-group">
    <TextField
      v-for="(field, index) in fields"
      :id="field.id"
      :key="index"
      :value="field.value"
      :label="field.label ? field.label : false"
      :placeholder="field.placeholder ? field.placeholder : ''"
      :options="field.options ? field.options : []"
      :legend="field.legend ? field.legend : false"
      :format="field.format ? field.format : false"
      :mask="field.mask ? field.mask : false"
      :width="field.width ? field.width : 'full'"
      :validate="field.validate ? field.validate : false"
      :customHandleChange="handleChildChange"
    />
    <div class="col-xs-12 extendable-fields-group__footer">
      <Button :label="addText" :action="handleAddField" />
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

import TextField from "@/components/TextField.vue";
import Button from "@/components/Button.vue";

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    field: {
      type: Object, // field to be cloned
      required: true
    },
    value: {
      type: Array,
      required: true
    },
    addText: {
      type: String,
      default: "Добавить"
    }
  },
  methods: {
    ...mapMutations("form", ["setValue"]),
    handleAddField() {
      let id = this.id + "_" + this.value.length;
      this.value.push({ id, value: "" });
      this.setValue({ id: this.id, value: this.value });
    },
    handleChildChange(
      data // data: {id,value}
    ) {
      // console.log(data);
      let values = this.value.filter(value => {
        return value.id == data.id;
      });

      if (values.length > 0) {
        values[0].value = data.value;
        this.setValue({ id: this.id, value: this.value });
      }
    }
  },
  computed: {
    fields() {
      const fields = [];
      this.value.map(
        function(val) {
          fields.push({
            ...this.field,
            value: val.value,
            id: val.id
          });
        }.bind(this)
      );
      // console.log(fields);
      return fields;
    }
  },
  components: {
    TextField,
    Button
  }
};
</script>
