import Cleave from 'cleave.js';
import maskTypeSwitcher from "@/helpers/masking/switchers/switcher";
import legacyMaskTypeSwitcher from "@/helpers/masking/switchers/legacySwitcher";

export default {
    props:{
        mask: {
            type: [String, Boolean, Object],
            default: false,
        },
    },
    data() {
        return {
            cleaveMask: null,
            nativeMask: null,
        };
    },
    methods:{
        setMask() {
            if (this.cleaveMask !== null || this.nativeMask !== null || !this.mask) {
              return;
            }
      
            const config = typeof this.mask === 'object' && this.mask?.type ? this.mask.type : this.mask;
            const type = legacyMaskTypeSwitcher(config);
            const mask = maskTypeSwitcher({
              type,
              setValue: this.setValue,
              mask: this.mask,
              id: this.id,
            });
      
            if (!mask) {
              return;
            }
      
            switch (mask.type) {
              case 'cleave':
                this.cleaveMask = new Cleave(this.$refs.input, mask.config);
                break;
              case 'native':
                this.nativeMask = mask;
                break;
            }
        },
    },
    mounted() {
        this.setMask();
    },
    updated() {
        this.setMask();
    },
    beforeDestroy() {
        if (this.cleaveMask !== null) {
            this.cleaveMask.destroy();
        }
    },
}