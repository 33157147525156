<template>
  <!-- START: BROKERS -->
  <div class="col-xs-12">
    <div class="form-field">
      <div v-if="label" class="form-field__label">{{ label }}</div>
      <div
        v-if="iframeOpen"
        :class="'brokers__iframe-wrapper' + (this.loading ? ' is-loading' : '')"
      >
        <iframe
          :src="iframeLink"
          frameborder="0"
          scrolling="no"
          @load="stopLoading"
        ></iframe>
      </div>
      <div v-else class="row">
        <div
          v-for="(broker, index) in options"
          class="col-xs-12 col-sm-6"
          :key="index"
        >
          <a
            target="_blank"
            :href="broker.link"
            :data-type="broker.linkType"
            class="broker-link"
            @click="handleClick"
          >
            <img :src="broker.img" :alt="broker.title" class="boker-img" />
            <span class="broker-title">{{ broker.title }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- END: BROKERS -->
</template>

<script>
export default {
  data() {
    return {
      iframeOpen: false,
      iframeLink: "",
      loading: false
    };
  },
  methods: {
    handleClick(e) {
      if (e.currentTarget.getAttribute("data-type") == "iframe") {
        e.preventDefault();
        this.iframeLink = e.currentTarget.getAttribute("href");
        this.iframeOpen = true;
        this.startLoading();
      }
    },
    startLoading() {
      this.loading = true;
    },
    stopLoading() {
      this.loading = false;
    }
  },
  props: {
    options: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  }
};
</script>

<style>
.iframe-wrapper {
  width: 100%;
  height: 550px;
}

.iframe-wrapper iframe {
  width: inherit;
  height: inherit;
  overflow: hidden;
}

.iframe-wrapper iframe body {
  overflow: hidden !important;
}

.iframe-wrapper iframe html {
  overflow-x: hidden !important;
}
</style>
