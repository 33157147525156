<template lang="pug">
  .field
    TextField(
      v-if="isTextField"
      :id="field.id"
      :value="value"
      :label="field.label ? field.label : false"
      :placeholder="field.placeholder ? field.placeholder : ''"
      :options="data[field.id]['options'] ? data[field.id]['options'] : []"
      :hiddenOptions="field.hiddenOptions ? field.hiddenOptions : []"
      :legend="field.legend ? field.legend : false"
      :format="field.format ? field.format : false"
      :mask="field.mask ? field.mask : false"
      :validate="field.validate ? field.validate : false"
      :showError="showError ? showError : false"
      :visible="field.visible"
      :mutateFields="field.mutateFields"
      :mutateStages="field.mutateStages ? field.mutateStages:false"
      :kladr="field.kladr"
      :autoFormat="field.autoFormat"
      :endpoint="field.endpoint"
      :readonly="field.readonly"
    )

    CheckboxField(
      v-if="isCheckboxField"
      :id="field.id"
      :value="value"
      :label="field.label ? field.label : false"
      :validate="field.validate ? field.validate : false"
      :placeholder="field.placeholder ? field.placeholder : ''"
      :showError="showError ? showError : false"
      :mutateFields="field.mutateFields"
      :bbcode="field.bbcode ? field.bbcode : false"
      @change="onChange"
    )

    ImgField(
      v-if="isImgField"
      :id="field.id"
      :value="data[field.id]['value']"
      :label="field.label ? field.label : false"
      :maxFiles="field.maxFiles"
      :validate="field.validate ? field.validate : false"
      :maxSize="field.maxSize"
      :accept="field.accept"
      :showError="showError ? showError : false"
    )

    ExtendableFieldsGroup(
      v-if="isExtendableFieldsGroup"
      :id="field.id"
      :field="field.field"
      :value="value"
      :showError="showError ? showError : false"
    )

    InnButton(
      v-if="isInnButton"
      :fields="field.fields"
      :field="field.field"
      :text="field.text ? field.text : 'Узнать ИНН'"
      :legend="field.legend"
    )

    BrokersField(
      v-if="isBrokersField"
      :options="data[field.id]['options']"
      :label="field.label"
    )

    DescriptionField(
      v-if="isDescriptionField"
      v-bind="field"
    )
</template>

<script>
import { mapState } from "vuex";
import TextField from "@/components/TextField.vue";
import CheckboxField from "@/components/CheckboxField.vue";
import ExtendableFieldsGroup from "@/components/ExtendableFieldsGroup.vue";
import ImgField from "@/components/ImgField.vue";
import InnButton from "@/components/InnButton.vue";
import BrokersField from "@/components/BrokersField.vue";
import DescriptionField from "@/components/DescriptionField.vue";

export default {
  props: {
    field: {
      type: Object,
      required: true
    },
    showError: {
      type: Boolean,
      default: false
    }
  },
  components: {
    TextField,
    ExtendableFieldsGroup,
    CheckboxField,
    ImgField,
    InnButton,
    BrokersField,
    DescriptionField
  },
  computed: {
    ...mapState("form", ["data"]),
    value() {
      const fieldId = this.field.id;

      return typeof this.data[fieldId] == "object"
        ? this.data[fieldId]["value"]
        : this.data[fieldId];
    },
    isTextField() {
      const data = this.data;
      const fieldId = this.field.id;

      return (
        (typeof data[fieldId] == "object" &&
          (!data[fieldId]["type"] || data[fieldId]["type"] == "TextField")) ||
        typeof data[fieldId] == "string" ||
        typeof data[fieldId] == "number"
      );
    },
    isCheckboxField() {
      const data = this.data;
      const fieldId = this.field.id;

      return (
        typeof data[fieldId] == "object" &&
        data[fieldId]["type"] == "CheckboxField"
      );
    },
    isImgField() {
      const data = this.data;
      const fieldId = this.field.id;

      return (
        typeof data[fieldId] == "object" && data[fieldId]["type"] == "ImgField"
      );
    },
    isExtendableFieldsGroup() {
      const data = this.data;
      const fieldId = this.field.id;

      return (
        typeof data[fieldId] == "object" &&
        data[fieldId]["type"] == "ExtendableFieldsGroup"
      );
    },
    isInnButton() {
      const data = this.data;
      const fieldId = this.field.id;

      return (
        typeof data[fieldId] == "object" && data[fieldId]["type"] == "InnButton"
      );
    },
    isBrokersField() {
      const data = this.data;
      const fieldId = this.field.id;

      return (
        typeof data[fieldId] == "object" && data[fieldId]["type"] == "BrokersField" 
      );
    },
    isDescriptionField() {
      const fieldType = this.field.type;

      const data = this.data;
      const fieldId = this.field.id;

      return (
          fieldType == "description" || typeof data[fieldId] == "object" && data[fieldId]["type"] == "TextViewField"
      );
    }
  },
  methods: {
    onChange({ value }) {
      this.$emit("change", { fieldId: this.field.id, value });
    }
  }
};
</script>
