<template>
  <div :class="widthClass">
    <div class="form-field">
      <label v-if="label" class="form-field__label">
        {{ computedLabel }} <span v-if="required" class="required">*</span>
        <span v-if="tooltip" class="form-field-tooltip">
          <span class="form-field-tooltip__box">?</span>
          <span class="form-field-tooltip__body">{{ tooltip }}</span>
        </span>
      </label>
      <div class="row">
        <div v-for="(file, key) in files" :key="key" class="col-xs-12 col-sm-4">
          <ImgPreviewer
            @file-uploaded="handleFileUploaded"
            @file-deleted="handleFileDeleted"
            @file-fail-size="handleFileFailSize"
            :id="file.id"
            :src="file.src"
            :accept="accept"
            :maxSize="maxSize"
          />
        </div>
      </div>
      <div v-if="sizeError" class="form-field__state">
        <div class="form-field__error">
          <span>{{ sizeError }}</span>
        </div>
      </div>
      <!-- START: ERROR -->
      <div v-if="error && showError" class="form-field__state">
        <div class="form-field__error">
          <span>{{ error.error }}</span>
        </div>
      </div>
      <!-- END: ERROR -->
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import Validator from "@/vendor/validate-us.js";
import ImgPreviewer from "@/components/ImgPreviewer.vue";

const uuid = require("uuid/v1");

export default {
  data() {
    return {
      sizeError: ""
    };
  },
  components: {
    ImgPreviewer
  },
  methods: {
    ...mapMutations("form", ["setValue"]),
    handleFileFailSize() {
      this.sizeError =
        "Недопустимый размер файла, разрешается заружать файлы размером до " +
        this.maxSize +
        " Мб";
    },
    handleFileUploaded(data) {
      this.sizeError = "";
      let files = [...this.value];

      var i = -1;
      files.map((file, k) => {
        if (file.id == data.id) {
          file.src = data.src;
          i = k;
        }
      });
      if (i == -1) {
        files.push({
          id: data.id,
          src: data.src
        });
      }
      this.setValue({
        id: this.id,
        value: files
      });
    },
    handleFileDeleted(id) {
      let files = [...this.value];
      let i = -1;

      files.map((file, k) => {
        if (file.id == id) {
          i = k;
        }
      });
      if (i > -1) {
        files.splice(i, 1);
        this.setValue({
          id: this.id,
          value: files
        });
      }
    }
  },
  computed: {
    // Error
    error() {
      if (this.validate) {
        let error = Validator.validateField({
          value: this.value,
          validate: this.validate
        });

        return error !== true ? error : "";
      }

      return "";
    },
    files() {
      let files = [...this.value];
      if (this.filesLeft > 0) {
        files.push({
          id: uuid(),
          src: ""
        });
      }
      return files;
    },
    filesCount() {
      return this.value.length;
    },
    filesLeft() {
      return this.maxFiles - this.filesCount;
    },
    required() {
      if (this.validate && this.validate.required) {
        return true;
      } else {
        return false;
      }
    },
    computedLabel() {
      let parts = this.label.split("[*]");
      return parts[0];
    },
    tooltip() {
      let matches = this.label.match(/(\[\*\])(.*)(\[\/\*\])/);
      if (matches != null) {
        return matches[2];
      } else return false;
    },
    widthClass() {
      switch (this.width) {
        case "one-half":
          return "col-xs-12 col-sm-6";
        case "one-third":
          return "col-xs-12 col-sm-4";
        case "one-fourth":
          return "col-xs-12 col-sm-3";
        case "full":
        default:
          return "col-xs-12";
      }
    }
  },
  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: Array
    },
    label: {
      type: String
    },
    maxFiles: {
      type: Number,
      default: 1
    },
    maxSize: {
      type: Number,
      default: 3
    },
    accept: {
      type: String,
      default: "image/jpeg,image/png,image/jpeg"
    },
    width: {
      type: String,
      default: "full"
    },
    validate: {
      type: [Object, Boolean],
      default: false
    },
    showError: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style>
.form-field__img-previewer {
  position: relative;
  display: flex;
  justify-content: center;
  height: 145px;
  overflow: hidden;
  border: 1px dashed #cdcdcd;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.2s linear;
  margin-bottom: 15px;
}
.form-field__img-previewer:hover {
  border-color: white;
  box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.1);
}
.form-field__img-previewer input {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
.form-field__img-previewer img {
  position: relative;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  height: auto;
  z-index: 1;
  opacity: 0;
}
.form-field__img-previewer img[src] {
  opacity: 1;
}
.img-previewer__closer {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 3;
  line-height: 1.15;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
  background-color: black;
  font-size: 20px;
  color: white;
  opacity: 0.5;
  cursor: pointer;
}
.img-previewer__closer:hover {
  opacity: 1;
}
.img-previewer__info {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 40px;
}
.img-previewer__title {
  font-size: 20px;
}
.img-previewer__description {
  opacity: 0.5;
}
.form-field__img-previewer--with-image {
  border-style: solid !important;
  border-color: #cdcdcd !important;
  box-shadow: none !important;
}
</style>
