<template lang="pug">
  Box
    Loader(v-if='loading')
    ServiceForm(v-else)
</template>

<script>
import store from "@/store";
import Box from "@/components/Box.vue";
import ServiceForm from "@/components/ServiceForm.vue";

export default {
  name: "FormWrap",
  data: () => ({
    loading: true,
  }),
  components: {
    ServiceForm,
    Box
  },
  beforeRouteEnter(to, from, next) {
    const id = store.getters["services/current"];
    const getServiceById = store.getters["services/item"];
    const service = getServiceById(id);

    if (service) {
      store.commit("form/setState", service.form);
      if (service.title) {
        to.meta.title = service.title;
      }
    }

    next(vm => {
      vm.serviceId = id;
    });
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 600);
    console.log("on form page");
  }
};
</script>
