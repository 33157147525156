<template>
  <div>
    <p :class="{'description-text__bold': bold}"><span v-html="textData"/></p>
  </div>
</template>

<script>
import bbobHTML from '@bbob/html'
import presetHTML5 from '@bbob/preset-html5'

export default {
  name: "DescriptionField",
  props: {
    text: {
      type: String,
      require: true
    },
    bold: {
      type: Boolean,
      default: false
    },
    bbcode: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    textData () {
      if (this.bbcode) {
        return bbobHTML(this.text, presetHTML5())
      }
      return this.text
    }
  },
}
</script>

<style scoped>
p {
  color: #989898;
}

.description-text__bold {
  color: #333333;
  font-weight: 600;
  font-size: 21px;
  line-height: 1.23;
}
</style>